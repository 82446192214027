$navbar-dark-color: #fff;

@import "~bootstrap/scss/bootstrap";

.tab-pane {
  padding: 2em;
}

.main {
  padding: 2em;
}

.container {
  padding: 0;
}

.navbar-brand {
  font-size: 1em;
  text-transform: uppercase;

  .logo {
    width: 30px;
  }
}

@include media-breakpoint-up(md) {
  .navbar-brand {
    font-size: 1.3em;

    .logo {
      width: 80px;
    }
  }
}

.container-fluid {
  padding-top: 2em;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h2 {
  margin-top: 2em;
  font-size: 1.5rem;

  + table, + div, + p {
    margin-top: 20px;
  }
}

form
{
  padding: 1em;
}

.bg-bsbv-dark {
  background-color: rgba(46,46,46,1);
  color: white;
}

#footer {
  padding: 2em 4em;
  text-align: center;
}

.language-choices {
  margin: 2em auto 6em;
  max-width: 600px;
}

.language-choice {
  text-transform: uppercase;
  display: block;
  font-size: 1.2em;
  text-align: center;

  border: 1px solid black;

  &:nth-child(even) {
    border-bottom: 0;
    border-top: 0;
  }

  &:last-child {
    border-bottom: 1px solid black;
  }

  a {
    color: black;
    display: block;
    padding: 0.6em;

    &:hover {
      text-decoration: none;
      background-color: lightgray;
    }
  }
}

.progress + p {
  margin-top: 1em;
}

table {
  + h2 {
    margin-top: 2em;
  }

  + table {
    margin-top: 2em;
  }

  &.health-conditions, &.owner, &.pedigree {
    tr:first-child {
      td, th {
        border-top: none;
      }
    }

    th {
      width: 35%;
    }

    td {
      width: 65%;
    }
  }

  &.behaviour, &.metadata {
    tr:first-child {
      td, th {
        border-top: none;
      }
    }
  }

  &.dog {
    tr:first-child {
      td, th {
        border-top: none;
      }
    }

    th {
      width: 35%;
    }

    td {
      width: 65%;
    }
  }
}

p {
  + h2 {
    margin-top: 1.5em;
  }
}

div.conditions {
  table:nth-child(even) {
    background-color: #F1F1F1;
    border-color: darkgrey;
  }
}

.registration-summary {
  .dog-name {
    td, th {
    border-top: 1px solid gray;
    }
  }

  .total {
    td, th {
      border-top: 1px solid gray;
    }
  }
}

.custom-file-label { opacity: 0; }
.custom-file-input { opacity: 1; }

.btn-group .form-group + .form-group {
  margin-left: 2em;
}